const NO_ACCESS_TOKEN = "NO_ACCESS_TOKEN";
const NOT_ENABLED = "NOT_ENABLED";
const NO_CUSTOMER = "NO_CUSTOMER";
const NO_LTI = "NO_LTI";
const LICENSE_EXPIRED = "LICENSE_EXPIRED";
const NO_LEARNER_EMAIL_AVAILABLE = "NO_LEARNER_EMAIL_AVAILABLE";
const NO_LEARNER_NAME_AVAILABLE = "NO_LEARNER_NAME_AVAILABLE";
const NOT_FOUND = "NOT_FOUND";
const USER_HASNT_FINISHED_WATCHING_THE_COURSE = "USER_HASNT_FINISHED_WATCHING_THE_COURSE";
const GENERIC_ERROR_MESSAGE = "GENERIC_ERROR_MESSAGE";

type ErrorType
  = typeof NO_ACCESS_TOKEN
    | typeof NOT_ENABLED
      | typeof NO_CUSTOMER
        | typeof NO_LTI
          | typeof LICENSE_EXPIRED
            | typeof NO_LEARNER_EMAIL_AVAILABLE
              | typeof NO_LEARNER_NAME_AVAILABLE
                | typeof NOT_FOUND
                 | typeof USER_HASNT_FINISHED_WATCHING_THE_COURSE
                  | typeof GENERIC_ERROR_MESSAGE;

type ErrorPageParams = {
                  errorToken: ErrorType;
}
export type {ErrorType, ErrorPageParams};
export {NO_ACCESS_TOKEN, NOT_ENABLED, NO_CUSTOMER, NO_LTI, LICENSE_EXPIRED, NO_LEARNER_EMAIL_AVAILABLE,
  NO_LEARNER_NAME_AVAILABLE, NOT_FOUND, USER_HASNT_FINISHED_WATCHING_THE_COURSE, GENERIC_ERROR_MESSAGE};
