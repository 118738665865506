import { FC } from "react";
import { useAppToken } from "../../app/hooks/useAppToken";
import { classNames } from "../../utils/classnames";

export type NavItemProps = {
  label: string
  href: string
  badge?: number
}

const Badge: FC<{ value: number }> = (props) => {
  const value = Math.min(props.value, 9);
  return <div className="
    w-4 h-4
    flex items-center justify-center
    text-[0.6rem] font-bold text-white bg-gray-500 rounded-full"
  >{value}</div>;
};

export const NavItem: FC<NavItemProps> = ({ label, href, badge }) => {
  const token = useAppToken();
  const isActive = window.location.pathname.endsWith(href)
    || window.location.pathname.endsWith(`${href}/${token}`);

  return <li className="">
    <a
      className={classNames(
        "px-2 py-1 rounded-full border border-transparent",
        "flex flex-row items-center gap-2",
        isActive ? "bg-gray-200 text-gray-700" : "text-white hover:border-gray-200",
      )}
      href={isActive ? undefined : href}
    >
      {label}
      {!isActive && badge && <Badge value={badge} />}
    </a>
  </li>;
};
