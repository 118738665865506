import { useEffect, useState } from "react";

import { Exam, SubmitAnswersPayload, SubmitAnswersResponse } from "../@types/exam.types";
import { executeRestAPICall } from "../services/generic.service";


export const getExam = async (courseId: string): Promise<Exam|undefined> => {
  try {
    return await executeRestAPICall({
      method: "GET",
      url: `${process.env.REACT_APP_LTI_BACKEND_API}/api/exams/${courseId}`,
    });
  } catch (error) {
    return undefined;
  }
};


export async function submitAnswers(id: number, payload: SubmitAnswersPayload): Promise<SubmitAnswersResponse> {
  return await executeRestAPICall({
    method: "POST",
    url: `${process.env.REACT_APP_LTI_BACKEND_API}/api/exam-results/${id}`,
    data: payload
  });
}


export const useLoadExam = (courseId: string) => {
  const [exam, setExam] = useState<Exam>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadExam = async () => {
      setLoading(true);
      const exam = await getExam(courseId);
      setLoading(false);
      setExam(exam);
    };
    loadExam();
  }, [courseId]);

  return { exam, loading };
};


export const useLazySubmitAnswers = () => {
  const [result, setResult] = useState<SubmitAnswersResponse>();
  const [loading, setLoading] = useState(false);
  const lazySubmit = async (id: number, payload: SubmitAnswersPayload) => {
    setLoading(true);
    const result = await submitAnswers(id, payload);
    setLoading(false);
    setResult(result);
  };

  return { result, loading, lazySubmit };
};
