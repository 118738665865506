import { createBrowserRouter} from "react-router-dom";
import { Courses } from "./courses/courses.component";
import { Errors } from "./errors/errors.component";
import { CourseDetails } from "./course-details/course-details.component";
import { Logout } from "./Logout";
import { ExamPage } from "./exam/components/exam-page";
import { CertificateDetailsPage } from "./certificate/CertificateDetailsPage";
import { RecentCoursesPage } from "./components/Pages/RecentCoursesPage";
import { AppTokenRedirect } from "./app/AppTokenRedirect";
import { CertificatesPage } from "./certificate/CertificatesPage";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    Component: Courses,
  },
  {
    path: "/recent",
    Component: RecentCoursesPage,
  },
  {
    path: "/certificates",
    Component: CertificatesPage,
  },
  {
    path: "/errors/:errorToken",
    Component: Errors,
  },
  {
    path: "/courses/:token",
    Component: AppTokenRedirect,
  },
  {
    path: "/course-details/:id",
    Component: CourseDetails,
  },
  {
    path: "/logout",
    Component: Logout,
  },
  {
    path: "/exam/:courseId",
    Component: ExamPage,
  },
  {
    path: "/certificate",
    Component: CertificateDetailsPage
  },
  {
    path: "*",
    Component: Errors,
  },
]);

export {AppRouter};
