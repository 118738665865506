import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export type TokenParams = {
  token: string;
};

const LTI_APP_TOKEN_KEY = "lti_app_token";

export const useAppToken = () => {
  const { token: urlToken } = useParams<TokenParams>();

  useEffect(() => {
    if (urlToken && urlToken !== localStorage.getItem(LTI_APP_TOKEN_KEY)) {
      localStorage.setItem(LTI_APP_TOKEN_KEY, urlToken);
    }
  }, [urlToken]);

  const token = useMemo(() =>
    urlToken || localStorage.getItem(LTI_APP_TOKEN_KEY),
  [urlToken]);


  return token;
};
