import { useMemo } from "react";

import { SubmitAnswersPayload } from "../../@types/exam.types";
import { useLazySubmitAnswers, useLoadExam } from "../exam.service";


const tupleToPayload = ([key, answerId]: [string, number]) =>
  ({ questionId: Number(key), answerId });


export const useExam = (courseId: string) => {
  const { exam, loading } = useLoadExam(courseId);
  const { result, lazySubmit } = useLazySubmitAnswers();
  const questionCount = exam ? Math.min(exam.totalQuestions, exam.examQuestions?.length) : 0;
  const questions = useMemo(() => {
    const result = (exam && exam.examQuestions)
      ? [...exam.examQuestions]
        .sort(() => 0.5 - Math.random())
        .slice(0, questionCount)
      : [];

    result.forEach((q) => q.examQuestionReponses.sort(() => 0.5 - Math.random()));
    return result;
  }, [exam, questionCount, exam?.examQuestions]);

  const submitAnswers = (answers: Record<number, number>) => {
    const payload: SubmitAnswersPayload = Object.entries(answers).map(tupleToPayload);
    if (courseId) lazySubmit(Number(courseId), payload);
  };

  return {
    exam,
    questions,
    result,
    submitAnswers,
    loading
  };
};

