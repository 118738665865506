import { FC, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CertificateViewer } from "./certificate-viewer";
import { getCertificateById, useLazyGetCertificateByCourseId } from "./certificate.service";
import { Loader } from "../loaders/loader.component";
import useApi from "../app/hooks/useApi";
import { useErrorRedirect } from "../app/hooks/useErrorRedirect";


const Error = () => {
  return <div>There was an error loading the certificate</div>;
};

export const CertificateDetailsPage: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get("course");
  const certificateId = searchParams.get("certificate");
  const {
    getCertificate,
    certificate: certificateByCourseId,
    error: certificateByCourseIdError
  } =
    useLazyGetCertificateByCourseId();

  const {
    data: certificateById,
    error: certificateByIdError,
    call: lazyGetCertificateById,
  } = useApi(getCertificateById);

  const error = certificateByIdError || certificateByCourseIdError;
  const certificate = certificateById || certificateByCourseId;

  useEffect(() => {
    if (courseId) {
      getCertificate(+courseId);
    } else if (certificateId) {
      lazyGetCertificateById(+certificateId);
    } else {
      useErrorRedirect(true);
    }
  }, [courseId, certificateId]);

  const content =
    error ? <Error />
      : certificate ? <CertificateViewer
        goBack={() => navigate(-1)}
        certificate={certificate}
      />
        : <Loader />;


  return (
    <main className="h-full">
      {content}
    </main>);
};
