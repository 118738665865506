import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import useApi, { VoidParam } from "../../app/hooks/useApi";
import { getRecentProgress } from "../../course-details/course-progress.service";
import { CourseGrid } from "../../courses/course-grid";
import { Loader } from "../../loaders/loader.component";
import { PageLayout as Page } from "./PageLayout";
import { PageTitle } from "./PageTitle";
import { useErrorRedirect } from "../../app/hooks/useErrorRedirect";


const NoOpenCourses: FC = () => {
  return <p className="text-sm sm:text-base md:text-lg text-gray-700">
    <Trans i18nKey="recentCourses.noCoursesSubtitle">
      You dont have any open courses right now, the
      <a href="/" className="
        font-semibold text-electric-1000 hover:text-electric-600
        underline decoration-electric-1000 underline-offset-4">
        course library</a> has more waiting for you.
    </Trans>
  </p>;
};

export const RecentCoursesPage: FC = () => {
  const { data, isLoading, error } = useApi(getRecentProgress, VoidParam);
  useErrorRedirect(error);
  const recent = data;
  const { t } = useTranslation(undefined, { keyPrefix: "recentCourses" });


  const content = isLoading ? <Loader />
    : recent?.length ? <CourseGrid courses={recent} />
      : null;

  return <Page>
    <PageTitle title={t`title`} subtitle={
      recent?.length
        ? t`subtitle`
        : <NoOpenCourses />
    } />
    {content}
  </Page>;
};
