import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppToken } from "./hooks/useAppToken";
import { NO_ACCESS_TOKEN } from "../@types/errors.types";
import { Loader } from "../loaders/loader.component";

export const AppTokenRedirect: FC = () => {
  const token = useAppToken();
  const navigate = useNavigate();
  useEffect(() => {
    navigate(token
      ? `/`
      : `/errors/${NO_ACCESS_TOKEN}`
    );
  }, []);
  return <Loader />;
};
