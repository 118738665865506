import { useTranslation } from "react-i18next";
import "../i18n";
import {ErrorType, LICENSE_EXPIRED, NOT_ENABLED, NO_ACCESS_TOKEN, NO_CUSTOMER, NO_LTI, NO_LEARNER_EMAIL_AVAILABLE, NO_LEARNER_NAME_AVAILABLE, NOT_FOUND, USER_HASNT_FINISHED_WATCHING_THE_COURSE, GENERIC_ERROR_MESSAGE } from "../@types/errors.types";


const useErrors = (error:ErrorType) => {
  const {t} = useTranslation();
  const errorTranslationKeys: Record<ErrorType, {title: string, description: string}> = {
    [NO_ACCESS_TOKEN]: {
      title: t("errors.NO_ACCESS_TOKEN.title"),
      description: t("errors.NO_ACCESS_TOKEN.description")
    },
    [NOT_ENABLED]: {
      title: t("errors.NOT_ENABLED.title"),
      description: t("errors.NOT_ENABLED.description")
    },
    [NO_CUSTOMER]: {
      title: t("errors.NO_CUSTOMER.title"),
      description: t("errors.NO_CUSTOMER.description")
    },
    [NO_LTI]: {
      title: t("errors.NO_LTI.title"),
      description: t("errors.NO_LTI.description")
    },
    [LICENSE_EXPIRED]: {
      title: t("errors.LICENSE_EXPIRED.title"),
      description: t("errors.LICENSE_EXPIRED.description")
    },
    [NO_LEARNER_EMAIL_AVAILABLE]: {
      title: t("errors.NO_LEARNER_EMAIL_AVAILABLE.title"),
      description: t("errors.NO_LEARNER_EMAIL_AVAILABLE.description")
    },
    [NO_LEARNER_NAME_AVAILABLE]: {
      title: t("errors.NO_LEARNER_NAME_AVAILABLE.title"),
      description: t("errors.NO_LEARNER_NAME_AVAILABLE.description")
    },
    [NOT_FOUND]: {
      title: t("errors.NOT_FOUND.title"),
      description: t("errors.NOT_FOUND.description")
    },
    [USER_HASNT_FINISHED_WATCHING_THE_COURSE]: {
      title: t("errors.USER_HASNT_FINISHED_WATCHING_THE_COURSE.title"),
      description: t("errors.USER_HASNT_FINISHED_WATCHING_THE_COURSE.description")
    },
    [GENERIC_ERROR_MESSAGE]: {
      title: t("errors.GENERIC_ERROR.title"),
      description: t("errors.GENERIC_ERROR.description")
    }
  };

  const {title, description} = errorTranslationKeys[error]
      ?? errorTranslationKeys.GENERIC_ERROR_MESSAGE;
  return {
    title,
    description
  };
};
export {useErrors};
