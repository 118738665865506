import { useCallback, useEffect, useState } from "react";

export const VoidParam = Symbol("VoidParams");
export type Endpoint<T, U> = (params: T) => Promise<U>;
export type Params<T> = T extends undefined ? typeof VoidParam : T;

/**
  * For eager loading, provide params as second argument.
  * If the endpoint does not take any params, call the endpoint with
  * the `VoidParam` symbol to imply eager loading.
  * For lazy loading, use the returned `call` function.
  * @todo Caching (via context?)
  */
const useApi = <T, U>(endpoint: Endpoint<T, U>, params?: Params<T>) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<U>();

  const call = useCallback(async (params: T) => {
    setLoading(true);
    setError(false);
    try {
      const response = await endpoint(params);
      setData(response);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [endpoint]);

  useEffect(() => {
    const eager = !!params;
    const trueParams = params === VoidParam ? undefined : params;
    if (eager) call(trueParams as T);
  }, []);

  return { isLoading, error, data, call };
};

export default useApi;
