import { FC, ReactElement } from "react";
import { NavItemProps } from "./NavItem";


export type NavListProps = {
  children: ReactElement<NavItemProps>[]
}

export const NavList: FC<NavListProps> = ({children}) => {
  return <nav className="text-white">
    <ul className="flex flex-col items-start md:flex-row gap-4">
      {children}
    </ul>
  </nav>;
};
