import { executeRestAPICall } from "../services/generic.service";
import { CourseProgress, CourseProgressWithCourse } from "./course-progress.types";

const COURSE_PROGRESS_API = `${process.env.REACT_APP_LTI_BACKEND_API}/api/course-progresses`;
async function getCourseProgress(courseId: string): Promise<CourseProgress> {
  const courseProgress: CourseProgress = await executeRestAPICall({
    method: "GET",
    url: `${COURSE_PROGRESS_API}/${courseId}`,
  });
  return courseProgress;
}
async function createCourseProgress(courseId: string) {
  await executeRestAPICall({
    method: "POST",
    url: `${COURSE_PROGRESS_API}/${courseId}`,
  });
}
async function updateCourseProgress(courseId: string, data: CourseProgress) {
  await executeRestAPICall({
    method: "PUT",
    url: `${COURSE_PROGRESS_API}/${courseId}`,
    data
  });
}

async function getRecentProgress(): Promise<CourseProgressWithCourse[]> {
  const recentProgress = await executeRestAPICall({
    method: "GET",
    url: `${COURSE_PROGRESS_API}/recent`,
  });
  return recentProgress;
}

async function getRecentProgressCount(): Promise<number> {
  return await executeRestAPICall({
    method: "GET",
    url: `${COURSE_PROGRESS_API}/recentCount`,
  });
}

export {
  getCourseProgress,
  createCourseProgress,
  updateCourseProgress,
  getRecentProgress,
  getRecentProgressCount,
};
