import { FC, ReactElement, useEffect, useState } from "react";
import { useWindowSize } from "../../app/hooks/useWindowSize";
import { NavItemProps } from "./NavItem";
import { NavList } from "./NavList";


export type NavigationProps = {
  children: ReactElement<NavItemProps>[]
}

export const Navigation: FC<NavigationProps> = (props) => {
  const { children } = props;
  const [width] = useWindowSize();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showMenu]);

  const navList = <NavList>{children}</NavList>;


  if (width > 800) {
    return navList;
  } else {
    return <div className="-order-1 h-full flex">
      <button className="z-50" type="button" onClick={() => setShowMenu(!showMenu)}>
        <img className="text-white w-6" src={showMenu ? "/cross-white.svg" : "/burger.svg"} alt="Menu" />
      </button>
      <div className="">
        {showMenu && <div className="z-50 text-lg px-8 py-8 fixed left-0 right-0 top-20 bottom-0 bg-gray-800 ">
          {navList}
        </div>}
      </div>
    </div>;
  }
};

