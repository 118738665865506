import { MouseEventHandler, PropsWithChildren } from "react";
import { Button } from "./Button";

type ThumbnailButtonParams = PropsWithChildren<{
  label?:string
  classes?:string
  kind?:"primary"|"secondary"
  action:MouseEventHandler<HTMLButtonElement>
}>

const ThumbnailButton:React.FC<ThumbnailButtonParams> = ({children, classes, action, label, kind}) => {
  return <Button
    type="button"
    className={`${classes} rounded-md bg-gray-700 px-8 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-800`}
    action={action}
    kind={kind}
  >
    {children ?? label}
  </Button>;
};
export {ThumbnailButton};
